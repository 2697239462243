/**
 * This was a pinia store but it was causing problems with the tests:
 * - had to have setActivePinia(createPinia()) in every test
 * - which made it hard to use the store in the app because it was not the same instance
 * - tests seemed flaky eg PlayerName and UserName started failing after a yarn upgrade because the session was not working as expected
 * So this class emulates the old interface to make a migration easy
 */
import { reactive, watch } from 'vue';
import { Session } from '@/service/Session';
import { useTranslation } from '@/compositions/UseTranslation';
import { validateLanguage } from '@/service/Language';

// yeah its a global variable because the logged in user session is global across the app.
// its a Vue thing.
const session = reactive(new Session());

watch(() => session.user?.language, () => {
  if (!session.user?.language) {
    // if language is not set, don't change the locale
    return;
  }
  // if the users language changes, update the i18n locale so the app is displayed in the right language
  const wantedLanguage = validateLanguage(session.user?.language);
  const {locale} = useTranslation();
  locale.value = wantedLanguage;
});

export const useSession = () => {
  return session;
};
