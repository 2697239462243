/**
 * if the api returns an error status code, display that for the user
 *
 * We currently -suppress- the error, so the caller does not get an exception from the api call.
 * This helps us have very streamlined code throughout the app because we can assume that any error handling /
 * reporting is done here, so we don't need to worry about it everywhere else
 * All we need to do is check for a non null response from our api call to know we got the wanted response back.
 */
import { useNotifier } from '@/compositions/UseNotifier';
import { ErrorResponse } from '@/api';
import { AxiosError } from 'axios';
import { useTranslation } from '@/compositions/UseTranslation';

const {notifyError} = useNotifier();

type MessageResponse = {
  message: string;
}

function shouldIgnore(error: AxiosError): boolean {
  // if a url matches this pattern, we will not display the error to the user
  const ignoreErrorsMatching = [
    /^\/content\/find-public\/terms.*/,
    /^\/auth\/verify-mfa/,
    /^\/auth\/player-signup/, // probably a duplicate email address
    /^\/auth\/login$/,
    // /user/id/send-registration-email, player signup
  ];

  return ignoreErrorsMatching.some(regex => regex.test(error.config?.url ?? ''));
}

// user defined type guard to check for an error message from the api
export function isErrorResponse(object: unknown): object is ErrorResponse {
  return !!object
    && Object.prototype.hasOwnProperty.call(object, 'messageId');
  // && Object.prototype.hasOwnProperty.call(object, 'statusCode');
}

export function isMessageResponse(object: unknown): object is MessageResponse {
  return !!object
    && Object.prototype.hasOwnProperty.call(object, 'message');
  // && Object.prototype.hasOwnProperty.call(object, 'statusCode');
}

export function displayErrorMessage(error: AxiosError): null {
  if (shouldIgnore(error)) {
    return null;
  }
  // keep this console.log in because notifyError() messages disappear on a timer but we may need to see them for debugging
  console.log('API error', error);

  const {t, te} = useTranslation();
  const response = error.response?.data;
  if (isErrorResponse(response)) {
    const key = `meta.api.${response.messageId}`;
    if (te(key)) {
      notifyError(t(key));
    } else {
      notifyError(t('meta.api.untranslatedError', {message: response.message}));
    }
  } else if (isMessageResponse(response)) {
    notifyError(response.message ?? t('meta.api.unknownError'));
  } else {
    notifyError(error.message ?? t('meta.api.unknownError'));
  }
  return null; // suppress the error because we have notified the user
}

