<template>
  <div>
    <v-alert v-for="(message, index) in messages" :key="message.expires" closable :type="message.type"
             @click:close="closeMessage(index)" :text="message.content" :data-cy="`message-${index}`" />
  </div>
</template>

<script lang="ts" setup>
import { useNotifier } from '@/compositions/UseNotifier';

const {messages, closeMessage} = useNotifier();
</script>
