import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { api } from '@/service/api';
import { useNotifier } from '@/compositions/UseNotifier';
import { useSession } from '@/compositions/UseSession';
import { useTranslation } from '@/compositions/UseTranslation';
import router from '@/plugins/router';

const {t} = useTranslation();

function setBearerToken(config: InternalAxiosRequestConfig, token: string): InternalAxiosRequestConfig {
  // eslint-disable-next-line
  config.headers['Authorization'] = `Bearer ${ token }`;
  return config;
}

export function addBearerToken(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  const session = useSession();
  if (api.requiresAccessToken(config.url) && session.accessToken) {
    return setBearerToken(config, session.accessToken);
  }
  if (api.requiresRefreshToken(config.url) && session.refreshToken) {
    return setBearerToken(config, session.refreshToken);
  }
  return config;
}

export async function refreshAccessToken(error: AxiosError): Promise<unknown> {
  // see https://stackoverflow.com/questions/68714143/how-can-i-use-axios-interceptors-to-add-some-headers-to-responses
  if (error.response?.status !== 401) {
    // 401 is a "please log in" message from the API: ignore everything else
    return Promise.reject(error);
  }

  if (api.isAuthUrl(error.config?.url)) {
    // Avoid infinite loop: trying to log in but the API is telling us we can't.
    // Also catches the case where /auth/refresh trying to get a new token fails
    return Promise.reject(error);
  }

  const session = useSession();
  if (!session.isLoggedIn) {
    // not logged in: the ui should handle the redirect for us, and displayErrorMessage() should display it...
    useNotifier().notifyError(t('meta.api.notLoggedIn'));
    await router.push('/auth');
    return Promise.resolve(error);
  }

  const originalRequest = error.config;
  const token = await api.refreshToken();
  if (token) {
    session.login(token);
    if (session.isLoggedIn && session.accessTokenIsValid && session.refreshTokenIsValid && !!originalRequest) {
      return api.axios(setBearerToken(originalRequest, session.accessToken ?? ''));
    }
    // notifyError('A server problem has occurred. Please log in again');
    // the token is corrupted somehow: our session is broken likely because the api returned an invalid token.
    // Prevent infinite recursion by logging out
  }
  // else /auth/refresh failed so just abort with the original error. The auth error should get displayed by displayErrorMessage()
  session.logout();
  return Promise.reject(error);
}
