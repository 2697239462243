/**
 * form validation rules
 *
 * The passwords.json file came from the top 100k passwords
 * https://github.com/danielmiessler/SecLists/blob/master/Passwords/Common-Credentials/10-million-password-list-top-100000.txt
 * and was filtered to remove
 * - less than 10 characters
 * - all repeated characters
 * then converted to json
 */
import Constants, {
  contentNameRegex, emailMaxLength, languageRegex, phoneMaxLength, phoneRegex, webSiteMaxLength
} from '@/service/Constants';
import { subYears } from 'date-fns';
import { useTranslation } from '@/compositions/UseTranslation';
import passwords from './passwords.json';
import { Role } from '@/api';

// const passwordHas = {
  // cognito
  // 8 character(s)
  // Contains at least 1 number
  // Contains at least 1 special character (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + =)
  // Contains at least 1 uppercase letter
  // Contains at least 1 lowercase letter
  // atLeastNCharacters: (password: string) => password.trim().length >= Constants.passwordMinLength,
  // atLeastOneDigit: (password: string) => password.match(/\d+/) !== null,
  // atLeastOneUpperCase: (password: string) => password.match(/[A-Z]+/) !== null,
  // atLeastOneLowerCase: (password: string) => password.match(/[a-z]+/) !== null,
  // atLeastOneSpecial: (password: string) => password.match(/[\-^$*.\[\]{}\(\)?"!@#%&\/,><':;|_~`+=]+/) !== null,
// };

const validEmail = /^[^@]+@([.\-\w+]+\.)+\w+$/;
const validWebSite = /^https?:\/\/[.\-\w+]+\.\w+[^/]$/;
const validUrl = /^https?:\/\/[-\w]+(\.[-\w]+)+([^\s]*)?$/;

// public for testing only
export function isComplexPassword(password: string, role: Role | undefined): boolean {
  const lowered = password.toLowerCase();
  if (passwords.includes(lowered)) return false;

  const repeatedCharacters = /(.).*\1{4,}/;
  if (repeatedCharacters.test(password)) return false;

  const stopWords = ['brain', 'health', 'world', 'rugby'];
  if (stopWords.includes(lowered)) return false;

  if (role === Role.Player) {
    return password.length >= Constants.passwordMinLengthPlayer;
  }
  return password.length >= Constants.passwordMinLength;
}

export default function useRules() {
  const {t} = useTranslation();

  const rules = {
    required: (val: string) => !!val || t('form.required'),
    requiredBoolean: (val: string | undefined | boolean) => val !== undefined || t('form.required'),
    requiredIf: (isRequired: boolean) => (val: string | null | undefined) => (!isRequired || (isRequired && (val !== undefined && val !== null && val !== ''))) || t('form.required'),
    language: (val: string) => !!val && languageRegex.test(val) || t('form.languageNotValid'),
    webSite: (val: string) => (!!val && validWebSite.test(val) && val.length <= webSiteMaxLength) || t('form.webSiteRequired', {webSiteMaxLength}),
    optionalWebSite: (val: string) => (!val || (validWebSite.test(val) && val.length <= webSiteMaxLength)) || t('form.webSiteRequired', {webSiteMaxLength}),
    optionalUrl: (val: string) => (!val || (validUrl.test(val) && val.length <= webSiteMaxLength)) || t('form.urlRequired', {webSiteMaxLength}),
    url: (val: string) => (!!val && validUrl.test(val) && val.length <= webSiteMaxLength) || t('form.urlRequired', {webSiteMaxLength}),
    email: (val?: string) => (!!val && validEmail.test(val) && val.length <= emailMaxLength) || t('form.emailRequired', {emailMaxLength}),
    optionalEmail: (val?: string) => (!val || (validEmail.test(val) && val.length <= emailMaxLength)) || t('form.emailRequired', {emailMaxLength}),
    phone: (val: string) => (!!val && phoneRegex.test(val) && val.length <= phoneMaxLength) || t('form.phoneRequired', {phoneMaxLength}),
    optionalPhone: (val: string) => (!val || (phoneRegex.test(val) && val.length <= phoneMaxLength)) || t('form.phoneRequired', {phoneMaxLength}),
    contentId: (val: string) => !!val && contentNameRegex.test(val) || t('form.contentIdRequired'),
    passwordValid: (role: Role | undefined) => (val: string) => (!!val && isComplexPassword(val, role)) || t('form.passwordNotValid', {length: role === Role.Player ? Constants.passwordMinLengthPlayer: Constants.passwordMinLength}),
    // optionalPasswordValid: (val: string) => !val || rules.passwordValid(val) || t('form.passwordNotValid', {length: Constants.passwordMinLength}),
    passwordMatches: (field: string) => (val: string) => val === field || t('form.passwordNotMatches'),
    year: (val: number) => (!val || (val >= 1900 && val <= 2100)) || t('form.yearOutOfRange'),
    isInteger: (val: number) => (!val || Number.isInteger(Number(val))) || t('form.notAnInteger'),
    weightKg: (val: number) => (!val || (val >= 0 && val <= 300)) || t('form.weightOutOfRange'),
    heightCm: (val: number) => (!val || (val >= 0 && val <= 300)) || t('form.heightOutOfRange'),
    birthDate: (val: string) => (!!val && val <= subYears(new Date(), 10).toISOString()) || t('form.birthDateInvalid'),
    numberBetween: (min: number, max: number) => (val?: number) => (val !== undefined && val >= min && val <= max) || t('form.numberBetween', {min, max}),
    numberBetweenOrBlank: (min: number, max: number) => (val?: number) => (!val || (val >= min && val <= max)) || t('form.numberBetween', {min, max}),
    maxLength: (length: number = 255) => (val: string) => (!val || val.length <= length) || t('form.shorterThan', {length}),
    notSameEmail: (others: Array<string>) => (val: string) => (!val || !others.includes(val)) || t('form.notSameEmail'),
    notSamePhone: (others: Array<string>) => (val: string) => (!val || !others.includes(val)) || t('form.notSamePhone'),
    // dateBlankOrAfter: (minDate?: string) => (val: string) => (!val || !minDate || val >= minDate) || `Must be after ${format.date(minDate)}`,
    // dateBlankOrBefore: (maxDate?: string) => (val: string) => (!val || !maxDate || val <= maxDate) || `Must be before ${format.date(maxDate)}`,
    // dateBlankOrInRange: (minDate?: string, maxDate?: string) => (val: string) => {
    //   if (!val) return true; // blank date is always valid
    //   if (!!minDate && !!maxDate && minDate > maxDate) return true; // illogical dates: must ignore
    //   const lowerBound = minDate || '1950-01-01';
    //   const upperBound = maxDate || '2100-01-01';
    //   if (val < lowerBound || val > upperBound) {
    //     return `Must be in the range ${format.date(lowerBound)} - ${format.date(upperBound)}`;
    //   }
    //   return true;
    // },
  };

  return {rules};
}
