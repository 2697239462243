/**
 * Used for the ajax loading bar: when we make an ajax request, push the url.
 * When we finish, pop it.
 * We are loading if the stack has any elements on it.
 *
 * Originally designed this as a class LoadingState but realised that a vue ref for 'loading'
 * would work better as it will update the ui only when needed, instead of having to poll a
 * class.isLoading() function all the time
 */
import { ref } from 'vue';

const ignoreUrls = [/^\/screenings\/\d+\/state/];
const activeUrls: string[] = [];
const loading = ref(false);

function start(url: string) {
  if (ignoreUrls.some(regex => regex.test(url))) return;
  activeUrls.push(url);
  loading.value = true;
}

function finish(url: string) {
  const index = activeUrls.indexOf(url);
  if (index >= 0) activeUrls.splice(index, 1);
  if (activeUrls.length <= 0) loading.value = false;
}

function clear() {
  activeUrls.length = 0;
  loading.value = false;
}

export default function useLoadingState() {
  return {loading, start, finish, clear};
}
