import axios from 'axios';
import { loadingBarStart, loadingBarStop, loadingBarStopOnError } from '@/service/api/interceptors/LoadingBar';
import { Environment } from '@/service/Environment';

// s3 for file uploads
const s3api = axios.create({timeout: Environment.getAPITimeout()});
// s3api will throw exceptions unless we add the response interceptor. But we need to poll for files with 401 responses so have to allow for it
// s3api.interceptors.response.use(response => response, displayErrorMessage);
s3api.interceptors.request.use(loadingBarStart);
s3api.interceptors.response.use(loadingBarStop, loadingBarStopOnError);

export { s3api };
