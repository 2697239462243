// GET /index.html to see if the timestamp has changed. If so, its probably a new version so let the user know
import { ref } from 'vue';
import { s3api } from '@/service/api/s3api';
import { differenceInMinutes } from 'date-fns';
import { Environment } from '@/service/Environment';

const millisecondsBetweenChecks = 10 * 60 * 1000; // every 10 minutes
let lastCheckTime = Date.now();
let isEnabled = true; // it will disable itself if there is a problem with HEAD /index.html
const updateAvailable = ref(false);

async function checkForUpdate() {
  if (!isEnabled) {
    return;
  }
  if (lastCheckTime + millisecondsBetweenChecks > Date.now()) {
    // its not time to check yet
    return;
  }
  lastCheckTime = Date.now();
  const url = window.location.origin ?? '';
  const response = await s3api.head(url + '/index.html');
  if (response.status !== 200) {
    isEnabled = false;
    console.log('Update checker disabled', response);
    return;
  }
  const thisModified = response.headers['last-modified'] ?? response.headers['etag'] ?? undefined;
  if (thisModified) {
    updateAvailable.value = differenceInMinutes(new Date(Number(thisModified)), new Date(Environment.getBuildDate())) > 0;
  }
}

export default function useUpdateChecker() {
  return {updateAvailable, checkForUpdate};
}
