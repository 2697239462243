/**
 * support to show and hide the loading bar when an ajax request is running
 */
import useLoadingState from '@/compositions/UseLoadingState';
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const {start, finish} = useLoadingState();

export function loadingBarStart(request: InternalAxiosRequestConfig<any>) {
  if (request.url) start(request.url);
  return request;
}

export function loadingBarStop(response: AxiosResponse<any, any>) {
  if (response.config.url) finish(response.config.url);
  return response;
}

export async function loadingBarStopOnError(error: AxiosError) {
  if (error.config?.url) finish(error.config.url);
  return Promise.reject(error);
}
