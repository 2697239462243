import { InternalAxiosRequestConfig } from 'axios';
import { useSession } from '@/compositions/UseSession';

export const ImpersonationHeader = 'X-Impersonate-User';

// if admin user is logged in as someone else then add a header that tells the api to impersonate that user id
export function addImpersonationHeader(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  const session = useSession();
  if (session.isImpersonating) {
    config.headers[ImpersonationHeader] = session.userId;
  }
  return config;
}
