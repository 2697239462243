import { Ref, ref } from 'vue';
import * as Sentry from '@sentry/vue';
import { Environment } from '@/service/Environment';

type Notification = {
  type: 'error' | 'success',
  content: string,
  expires: number
};

const autoDeleteTimeout = 10 * 1000;
const messages: Ref<Array<Notification>> = ref([]);
const lastError = ref('');

function closeMessage(index: number) {
  messages.value.splice(index, 1);
}

function clearMessages() {
  messages.value.length = 0;
  lastError.value = '';
}

function addMessage(type: Notification['type'], message: string) {
  if (type === 'error') lastError.value = message;
  // remove duplicates
  messages.value = messages.value.filter(m => !(m.type === type && m.content === message));
  // add new message
  const item = {type, content: message, expires: Date.now() + autoDeleteTimeout};
  messages.value.push(item);
  // auto delete on timeout
  setTimeout(() => {
    messages.value = messages.value.filter(m => m.expires > Date.now());
  }, autoDeleteTimeout);
}

function hasMessage(type: Notification['type'], message: string | RegExp) {
  return !!messages.value.find(m => m.type === type
    && (typeof message === 'string' ? m.content.includes(message) : m.content.match(message)));
}

function logToSentry(message: string, data?: object) {
  if (Environment.isUnitTest()) return;

  const extra = data ? (': ' + JSON.stringify(data)) : '';
  const exception = new Error(message + extra);
  Sentry.captureException(exception);
}

export function useNotifier() {
  return {
    notifyError: (message: string) => addMessage('error', message),
    notifySuccess: (message: string) => addMessage('success', message),
    messages, clearMessages, lastError, logToSentry, closeMessage, hasMessage
  };
}
