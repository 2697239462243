/**
 * Values we use in a lot of places
 */

// the block below is copy-pasted from the api repository src/utils/Constants.ts and must be kept in sync with it
export const databaseStringDefaultLength = 255;
export const passwordMinLength = 10; // the UI enforces a min length of 15 for admin roles
export const passwordMaxLength = 100;
export const nameMaxLength = 100;
export const stepMaxLength = 50; // step names
export const phoneMaxLength = 40;
export const emailMaxLength = databaseStringDefaultLength;
export const webSiteMaxLength = databaseStringDefaultLength;
export const stringMaxLength = databaseStringDefaultLength;
export const specialistTypesLength = databaseStringDefaultLength; // holds all the comma separated flags
export const assessmentFlagsLength = 500; // holds all the comma separated flags
export const timeZoneMaxLength = 60;
export const tokenMaxLength = 100; // mfa, bearer, access token
export const mfaMaxLength = 10; // 6 digit code
export const languageMaxLength = 5; // en-NZ. Controlled by languageRegex
export const playerSecretMaxLength = 10;
export const notesMaxLength = 2048; // must store this in a text field in the database
export const contentMaxLength = 100000; // must store this in a text field in the database
export const languageRegex = /^[a-z]{2}(-[A-Z]{2})?$/; // en-NZ, en-AU, de-CH
export const contentNameRegex = /^[a-zA-Z0-9.]+$/; // anExample.ofAName2
export const phoneRegex = /^\+[\d]{8,}$/;

// other random constants
export default {
  dateFormat: 'D MMM YYYY',
  dateTimeFormat: 'D MMM YYYY HH:mm',
  minDate: '2000-01-01', // earliest date on a date validator for new assessments
  passwordMinLength: 15, // characters
  passwordMinLengthPlayer: 10, // characters: Players can have shorter passwords
  defaultPageSize: 50,
  referralTitleClass: 'bg-blue-lighten-5'
};
