import { createI18n } from 'vue-i18n';
import english from '../locale/en.json';
import leetSpeak from '../locale/en-LT.json';
import hsilgne from '../locale/en-NE.json';
import fatfat from '../locale/en-FT.json';
import { en } from 'vuetify/locale';
import { useNavigatorLanguage } from '@vueuse/core';
import { AllowedLanguage, validateLanguage } from '@/service/Language';

function browserAcceptLanguage(): AllowedLanguage {
  // see what locale the user has set in their browser and use that if we have a matching language
  // see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language
  const {language} = useNavigatorLanguage();
  return validateLanguage(language.value ?? '');
}

// typescript trick https://vue-i18n.intlify.dev/guide/advanced/typescript.html#type-safe-resources-with-schema
type MessageSchema = typeof english & { $vuetify: object };
const i18n = createI18n<[MessageSchema], string, false>({
  legacy: false,
  locale: browserAcceptLanguage(),
  fallbackLocale: 'en',
  messages: { // if you see weird errors here, check the translation files all match the same schema
    'en': {
      $vuetify: en,
      ...english
    },
    'en-LT': { // generate this with yarn i18n:translate
      $vuetify: en,
      ...leetSpeak
    },
    'en-NE': { // generate this with yarn i18n:translate
      $vuetify: en,
      ...hsilgne
    },
    'en-FT': { // generate this with yarn i18n:translate
      $vuetify: en,
      ...fatfat
    },
    // see https://vuetifyjs.com/en/features/internationalization/#vue-i18n
    // when adding real languages you also need to set the vuetify locale for internal strings.
    // there is currently a vuetify bug that tries to find table strings in the translation file even though they have already been translated
  } satisfies Record<AllowedLanguage, MessageSchema>
})

export default i18n;
