import * as Sentry from "@sentry/vue";
import { Router } from 'vue-router';
import { Environment } from '@/service/Environment';
import type { App } from 'vue';

export default function enableSentry(app: App, router: Router) {
  Sentry.init({
    app,
    dsn: Environment.getSentryDSN(),
    integrations: [
      Sentry.browserTracingIntegration({router, instrumentNavigation: true}),
    ],
    enabled: !Environment.isUnitTest() && window.location.hostname !== 'localhost',
    tracePropagationTargets: [Environment.getAPI()],
    environment: Environment.getEnvironment(),
    release: Environment.getBuildNumber(),
    tracesSampleRate: 1.0,
    // todo strip out all but 1% of GET /screening/:id/state maybe: see tracesSampler in the api
    beforeSendSpan(span) {
      // strip a lot of noise out of the trace
      if (span.op === 'measure' && span.description?.startsWith('intlify')) return null;
      if (span.op === 'resource.script') return null;
      return span;
    }
  });
}
