/**
 * wraps the api calls in a composable
 * and handles loading state, error message display, refresh token resends and other stuff
 */
import { default as axiosFactory, AxiosResponse } from 'axios';
import qs from 'qs';
import {
  Assessment, AssessmentStep, AssessmentStepsOnPage, AssessmentStepsQuery, ChangePassword,
  Content, CreateAssessment, CreateAssessmentStep, CreatePlayer, CreateScreening, CreateUser,
  ForgotPassword, LoginRequest, LoginResponse, MFAChallenge, MFAResponse,
  OrganisationsOnPage, PaginatedQuery, Player, PlayerSignup, ResetPassword,
  UpdateAssessment, UpdateAssessmentStep, UpdatePlayer, UpdateScreening, UpdateUser,
  ScreeningsOnPage, ServerHealth, User, UserRegister, UsersOnPage, Organisation,
  ScreeningWithDetail, UserQuery, ScreeningQuery, ActivityWithDetail, ActivityQuery, ActivitiesOnPage, CreateActivity,
  SupporterInformation, Registration, PlayersWithScreeningOnPage, UpdateOrganisation, AssessmentQuery,
  AssessmentType, AssessmentsOnPage, CreateOrganisation, FilterContent, ContentOnPage, UpdateContent, CreateContent,
  CronJobsOnPage, CronJob, UpdateCronJob, ReferralRequest, ReferralResponse, DoctorReview,
  SupporterDecline, SupporterAccept, PlayerQuery, PlayersOnPage, AssessmentStepsExportQuery, ReferralSecrets,
  S3FileUpload, FilesOnPage, FileInformation, Activity, ContentTokens, CreateSpecialist, Specialist, UpdateSpecialist,
  SpecialistQuery, SpecialistsOnPage, StatisticsQuery, StatisticsOnPage
} from '@/api';
import { Environment } from '@/service/Environment';
import { displayErrorMessage } from '@/service/api/interceptors/DisplayErrors';
import { loadingBarStart, loadingBarStop, loadingBarStopOnError } from '@/service/api/interceptors/LoadingBar';
import { addBearerToken, refreshAccessToken } from '@/service/api/interceptors/AuthTokens';
import { addImpersonationHeader } from '@/service/api/interceptors/UserImpersonation';
import { s3uploadFileAndWait, sanitiseFileName } from '@/service/api/S3Files';
import { StepName } from '@/service/Assessment/StepNames';

const axios = axiosFactory.create({
  baseURL: Environment.getAPI(),
  timeout: Environment.getAPITimeout(),
  withCredentials: true, // allows cookies to work
  // aws api gateway requires array parameters as value=1,2,3 (with no [])
  // which is why we have to override the default axios serializer, which uses []
  paramsSerializer: {serialize: (params) => qs.stringify(params, {arrayFormat: 'comma', format: 'RFC1738'})}
});

axios.interceptors.request.use(loadingBarStart, loadingBarStopOnError);
axios.interceptors.response.use(loadingBarStop, loadingBarStopOnError);
axios.interceptors.request.use(addBearerToken);
axios.interceptors.request.use(addImpersonationHeader);
axios.interceptors.response.use(response => response, refreshAccessToken);
axios.interceptors.response.use(response => response, displayErrorMessage);

function isSuccessful(response?: AxiosResponse | null): boolean {
  return !!response && !!response.data;
}

async function createOne<T extends object, T2 extends object>(data: T, baseUrl: string): Promise<T2 | undefined> {
  const response = await axios.post<T2>(baseUrl, data);
  return response?.data;
}

async function updateOne<T extends object, T2 extends object>(data: T, url: string): Promise<T2 | undefined> {
  const response = await axios.patch<T2>(url, data);
  return response?.data;
}

async function getOne<T>(url: string, params = {}): Promise<T | undefined> {
  const response = await axios.get<T>(url, {params});
  return response?.data;
}

async function getPage<T extends object, Q extends PaginatedQuery>(url: string, query: Q): Promise<T | undefined> {
  const response = await axios.get<T>(url, {params: query});
  return response?.data;
}

async function deleteOne(url: string): Promise<boolean> {
  return isSuccessful(await axios.delete(url));
}

export const maxPlayerSecretFails = 5; // must match the constant in the api

/**
 * wraps axios and the brain health api openapi
 * uses the types / data structures from openapi generator but does not use the actual method calls
 * because
 * - openapi-generator was behaving weirdly under test and we couldn't figure out why, so we bypassed it
 * - it had an opinionated way of adding bearer tokens and we needed it to work different
 * - seemed like a lot of code to do not much. wanted something simpler eg the factory classes were awkward
 */
export const api = {
  axios, // for low level access only eg to retry for refresh tokens

  isAuthUrl: function (url: string | undefined): boolean {
    // true if the url is for authentication: so we don't need a bearer token
    // and should not try to refresh the access token if it is expired when calling one of these endpoints
    return /\/auth\/\w+/.test(url ?? '');
  },

  requiresAccessToken: function (url: string | undefined): boolean {
    // special case: logout needs the access token so it knows which to destroy.
    return !api.isAuthUrl(url) || url === '/auth/logout';
  },

  requiresRefreshToken: function (url: string | undefined): boolean {
    // true if the endpoint needs the refresh token
    return url === '/auth/refresh';
  },

  health: async function (): Promise<ServerHealth | undefined> {
    const response = await axios.get('/server/health');
    return response?.data;
  },

  // --------------------------------------------------------------------------------
  login: async function (request: LoginRequest): Promise<MFAChallenge | undefined> {
    const response = await axios.post('/auth/login', request);
    return response?.data;
  },

  verifyMFA: async function (mfa: MFAResponse): Promise<LoginResponse | undefined> {
    const response = await axios.post('/auth/verify-mfa', mfa);
    return response?.data;
  },

  resendMFA: async function (mfa: MFAResponse): Promise<MFAChallenge | undefined> {
    const response = await axios.post('/auth/resend-mfa', mfa);
    return response?.data;
  },

  logout: async function (): Promise<boolean> {
    return isSuccessful(await axios.post('/auth/logout'));
  },

  playerSignup: async function (request: PlayerSignup): Promise<boolean> {
    return isSuccessful(await axios.post('/auth/player-signup', request));
  },

  checkRegistration: async function (userId: number, token: string): Promise<Registration | undefined> {
    const response = await axios.get('/auth/register', {params: {id: userId, token}});
    return response?.data;
  },

  register: async function (request: UserRegister): Promise<LoginResponse> {
    const response = await axios.post('/auth/register', request);
    return response?.data;
  },

  oneClickLogin: async function (userId: number, token: string): Promise<LoginResponse | undefined> {
    const response = await axios.post('/auth/one-click-login', {id: userId, token});
    return response?.data;
  },

  refreshToken: async function (): Promise<LoginResponse | undefined> {
    const response = await axios.get('/auth/refresh');
    return response?.data;
  },

  forgotPassword: async function (request: ForgotPassword): Promise<boolean> {
    return isSuccessful(await axios.post('/auth/forgot', request));
  },

  resetPassword: async function (request: ResetPassword): Promise<boolean> {
    return isSuccessful(await axios.post('/auth/reset', request));
  },

  // --------------------------------------------------------------------------------
  getOrganisation: async function (id: number): Promise<Organisation | undefined> {
    return getOne(`/organisations/${ id }`);
  },

  getOrganisations: async function (query: PaginatedQuery): Promise<OrganisationsOnPage | undefined> {
    return getPage('/organisations', query);
  },

  createOrganisation: async function (organisation: CreateOrganisation): Promise<Organisation | undefined> {
    return createOne(organisation, `/organisations`);
  },

  updateOrganisation: async function (id: number, organisation: UpdateOrganisation): Promise<Organisation | undefined> {
    return updateOne(organisation, `/organisations/${ id }`);
  },

  deleteOrganisation: async function (id: number): Promise<boolean> {
    return deleteOne(`/organisations/${ id }`);
  },

  // --------------------------------------------------------------------------------
  getUsers: async function (query: UserQuery): Promise<UsersOnPage | undefined> {
    return getPage('/users', query);
  },

  getUsersLoggedIn: async function (query: UserQuery): Promise<UsersOnPage | undefined> {
    return getPage('/users/loggedin', query);
  },

  getUser: async function (id: number): Promise<User | undefined> {
    return getOne(`/users/${ id }`);
  },

  deleteUser: async function (id: number): Promise<boolean> {
    return deleteOne(`/users/${ id }`);
  },

  createUser: async function (user: CreateUser): Promise<User | undefined> {
    return createOne(user, '/users');
  },

  updateUser: async function (id: number, user: UpdateUser): Promise<User | undefined> {
    return updateOne(user, `/users/${ id }`);
  },

  lockUser: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.patch(`/users/${ id }/lock`));
  },

  unlockUser: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.patch(`/users/${ id }/unlock`));
  },

  approveUser: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.patch(`/users/${ id }/approve`));
  },

  declineUser: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.delete(`/users/${ id }/approve`));
  },

  changePassword: async function (request: ChangePassword): Promise<boolean> {
    return isSuccessful(await axios.patch('/users/me/password', request));
  },

  sendRegistrationEmail: async function (userId: number): Promise<boolean> {
    return isSuccessful(await axios.post(`/users/${ userId }/send-registration-email`));
  },

  sendOneClickLogin: async function (userId: number): Promise<boolean> {
    return isSuccessful(await axios.post(`/users/${ userId }/send-one-click-login`));
  },

  getUserFiles: async function (id: number, query: PaginatedQuery): Promise<FilesOnPage | undefined> {
    return getPage(`/users/${ id }/files`, query);
  },

  getUserFile: async function (id: number, name: string): Promise<FileInformation | undefined> {
    return getOne(`/users/${ id }/files/${ name }`);
  },

  uploadUserFile: async function (id: number, file: File, name?: string): Promise<string | undefined> {
    const fileName = name ?? sanitiseFileName(file.name);
    const parameters: S3FileUpload | undefined = (await axios.put(`/users/${ id }/files/${ fileName }`))?.data;
    return s3uploadFileAndWait(parameters, file);
  },

  deleteUserFile: async function (id: number, name: string): Promise<boolean> {
    return deleteOne(`/users/${ id }/files/${ name }`);
  },

  // --------------------------------------------------------------------------------
  getScreening: async function (id: number): Promise<ScreeningWithDetail | undefined> {
    return getOne(`/screenings/${ id }`);
  },

  getScreenings: async function (query: ScreeningQuery): Promise<ScreeningsOnPage | undefined> {
    return getPage('/screenings', query);
  },

  updateScreening: async function (id: number, data: UpdateScreening): Promise<ScreeningWithDetail | undefined> {
    return updateOne(data, `/screenings/${ id }`);
  },

  createScreening: async function (data: CreateScreening): Promise<ScreeningWithDetail | undefined> {
    return createOne(data, '/screenings');
  },

  deleteScreening: async function (id: number): Promise<boolean> {
    return deleteOne(`/screenings/${ id }`);
  },

  getScreeningFiles: async function (id: number, query: PaginatedQuery): Promise<FilesOnPage | undefined> {
    return getPage(`/screenings/${ id }/files`, query);
  },

  getScreeningFile: async function (id: number, name: string): Promise<FileInformation | undefined> {
    return getOne(`/screenings/${ id }/files/${ name }`);
  },

  uploadScreeningFile: async function (id: number, file: File, name: string | undefined = undefined): Promise<string | undefined> {
    const fileName = name ?? sanitiseFileName(file.name);
    const parameters: S3FileUpload | undefined = (await axios.put(`/screenings/${ id }/files/${ fileName }`))?.data;
    return s3uploadFileAndWait(parameters, file);
  },

  deleteScreeningFile: async function (id: number, name: string): Promise<boolean> {
    return deleteOne(`/screenings/${ id }/files/${ name }`);
  },

  setScreeningState: async function (id: number, state: string | undefined): Promise<boolean> {
    return isSuccessful(await axios.patch(`/screenings/${ id }/state`, {bhpMeetingState: state ?? ''}));
  },

  getScreeningState: async function (id: number): Promise<string | undefined> {
    const response = await axios.get(`/screenings/${ id }/state`);
    return response?.data?.bhpMeetingState;
  },

  refreshPlayerSecret: async function (id: number): Promise<ScreeningWithDetail | undefined> {
    const response = await axios.post(`/screenings/${ id }/refresh-player-secret`);
    return response?.data;
  },

  emailPlayerSecret: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.post(`/screenings/${ id }/email-player-secret`));
  },

  smsPlayerSecret: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.post(`/screenings/${ id }/sms-player-secret`));
  },

  getReferralSecrets: async function (id: number): Promise<ReferralSecrets | undefined> {
    return await getOne(`/screenings/${ id }/referral-secrets`);
  },

  unlockScreening: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.post(`/screenings/${ id }/unlock`));
  },

  screeningGetSupporterReview: async function (id: number, token: string): Promise<SupporterInformation> {
    return (await axios.get(`/supporters/${ id }`, {params: {token}}))?.data;
  },

  screeningSupporterAccept: async function (id: number, review: SupporterAccept): Promise<boolean> {
    return isSuccessful(await axios.post(`/supporters/${ id }/accept`, review));
  },

  screeningSupporterDecline: async function (id: number, review: SupporterDecline): Promise<boolean> {
    return isSuccessful(await axios.post(`/supporters/${ id }/decline`, review));
  },

  screeningSendSupporterEmail: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.post(`/screenings/${ id }/send-supporter-email`));
  },

  screeningSendDoctorEmail: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.post(`/screenings/${ id }/send-doctor-email`));
  },

  screeningSendMeetingLink: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.post(`/screenings/${ id }/send-meeting-link`));
  },

  screeningSendRetestLink: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.post(`/screenings/${ id }/send-retest-link`));
  },

  // --------------------------------------------------------------------------------
  getReferral: async function (id: number, request: ReferralRequest): Promise<ReferralResponse | undefined> {
    const response = await axios.post(`/referral/${ id }`, request);
    return response?.data;
  },

  saveDoctorReview: async function (id: number, request: DoctorReview): Promise<boolean> {
    return isSuccessful(await axios.post(`/referral/${ id }/doctor`, request));
  },

  // --------------------------------------------------------------------------------
  getPlayers: async function (query: PlayerQuery): Promise<PlayersOnPage | undefined> {
    return getPage('/players', query);
  },

  getPlayer: async function (id: number): Promise<Player | undefined> {
    return getOne(`/players/${ id }`);
  },

  getPlayerScreenings: async function (query: PlayerQuery): Promise<PlayersWithScreeningOnPage | undefined> {
    return getPage(`/players/screenings`, query);
  },

  updatePlayer: async function (id: number, data: UpdatePlayer): Promise<Player | undefined> {
    return updateOne(data, `/players/${ id }`);
  },

  createPlayer: async function (data: CreatePlayer): Promise<Player | undefined> {
    return createOne(data, '/players');
  },

  movePlayer: async function (playerId: number, organisationId: number): Promise<boolean> {
    return isSuccessful(await axios.patch(`/players/${ playerId }/move-to/${ organisationId }`));
  },

  mergePlayer: async function (fromPlayerId: number, toPlayerId: number): Promise<boolean> {
    return isSuccessful(await axios.patch(`/players/${ toPlayerId }/merge-from/${ fromPlayerId }`));
  },

  // --------------------------------------------------------------------------------
  getActivity: async function (id: number): Promise<ActivityWithDetail | undefined> {
    return getOne(`/activities/${ id }`);
  },

  getActivities: async function (query: ActivityQuery): Promise<ActivitiesOnPage | undefined> {
    return getPage('/activities', query);
  },

  addActivity: async function (activity: CreateActivity): Promise<Activity | undefined> {
    return createOne(activity, '/activities');
  },

  setActivityCompleted: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.patch(`/activities/${ id }/completed`));
  },

  setActivityNotCompleted: async function (id: number): Promise<boolean> {
    return isSuccessful(await axios.delete(`/activities/${ id }/completed`));
  },

  deleteActivity: async function (id: number): Promise<boolean> {
    return deleteOne(`/activities/${ id }`);
  },

  // --------------------------------------------------------------------------------
  getAssessments: async function (query: AssessmentQuery): Promise<AssessmentsOnPage | undefined> {
    return getPage(`/assessments`, query);
  },

  getSelfAssessment: async function (screeningId: number): Promise<Assessment | undefined> {
    const result = (await api.getAssessments({screeningId, assessmentType: AssessmentType.self}))?.data ?? [];
    return result.length > 0 ? result[0] : undefined;
  },

  getBhpAssessment: async function (screeningId: number): Promise<Assessment | undefined> {
    const result = (await api.getAssessments({screeningId, assessmentType: AssessmentType.bhp}))?.data ?? [];
    return result.length > 0 ? result[0] : undefined;
  },

  getRetestAssessment: async function (screeningId: number): Promise<Assessment | undefined> {
    const result = (await api.getAssessments({screeningId, assessmentType: AssessmentType.bhpRetest}))?.data ?? [];
    return result.length > 0 ? result[0] : undefined;
  },

  // getSupporterAssessment: async function (screeningId: number): Promise<Assessment | undefined> {
  //   const result = (await api.getAssessments({screeningId, assessmentType: AssessmentType.supporter}))?.data ?? [];
  //   return result.length > 0 ? result[0] : undefined;
  // },
  //
  getAssessment: async function (id: number): Promise<Assessment | undefined> {
    return getOne(`/assessments/${ id }`);
  },

  createAssessment: async function (assessment: CreateAssessment): Promise<Assessment | undefined> {
    return createOne(assessment, `/assessments`);
  },

  updateAssessment: async function (id: number, assessment: UpdateAssessment): Promise<Assessment | undefined> {
    return updateOne(assessment, `/assessments/${ id }`);
  },

  deleteAssessment: async function (id: number): Promise<boolean> {
    return deleteOne(`/assessments/${ id }`);
  },

  // --------------------------------------------------------------------------------
  getAssessmentSteps: async function (query: AssessmentStepsQuery): Promise<AssessmentStepsOnPage | undefined> {
    return getPage('/assessment-steps', query);
  },

  getAssessmentStepsForExport: async function (query: AssessmentStepsExportQuery): Promise<AssessmentStepsOnPage | undefined> {
    return getPage('/assessment-steps/export', query);
  },

  createAssessmentStep: async function (step: CreateAssessmentStep): Promise<AssessmentStep | undefined> {
    return createOne(step, `/assessment-steps`);
  },

  updateAssessmentStep: async function (id: number, step: UpdateAssessmentStep): Promise<AssessmentStep | undefined> {
    return updateOne(step, `/assessment-steps/${ id }`);
  },

  // --------------------------------------------------------------------------------
  getAllContent: async function (query: FilterContent): Promise<ContentOnPage | undefined> {
    return getPage(`/content`, query);
  },

  getContent: async function (id: number): Promise<Content | undefined> {
    return getOne(`/content/${ id }`);
  },

  getContentTokens: async function (name: string): Promise<ContentTokens | undefined> {
    return getOne(`/content/tokens/${ name }`);
  },

  findContent: async function (name: string, language: string, loggedIn: boolean = true): Promise<Content | undefined> {
    return getOne(`/content/find${ loggedIn ? '' : '-public' }/${ name }/${ language }`);
  },

  findPublicContent: async function (name: string, language: string): Promise<Content | undefined> {
    return getOne(`/content/find-public/${ name }/${ language }`);
  },

  createContent: async function (content: CreateContent): Promise<Content | undefined> {
    return createOne(content, `/content`);
  },

  updateContent: async function (id: number, step: UpdateContent): Promise<Content | undefined> {
    return updateOne(step, `/content/${ id }`);
  },

  deleteContent: async function (id: number): Promise<boolean> {
    return deleteOne(`/content/${ id }`);
  },

  getContentFiles: async function (id: number, query: PaginatedQuery): Promise<FilesOnPage | undefined> {
    return getPage(`/content/${ id }/files`, query);
  },

  uploadContentFile: async function (id: number, file: File): Promise<string | undefined> {
    const parameters: S3FileUpload | undefined = (await axios.put(`/content/${ id }/files/${ sanitiseFileName(file.name) }`))?.data;
    return s3uploadFileAndWait(parameters, file);
  },

  deleteContentFile: async function (id: number, name: string): Promise<boolean> {
    return deleteOne(`/content/${ id }/files/${ name }`);
  },

  // --------------------------------------------------------------------------------
  getScoringGuides: async function (query: PaginatedQuery): Promise<FilesOnPage | undefined> {
    return getPage(`/scoring-guide`, query);
  },

  uploadScoringGuide: async function (language: string, stepName: StepName, file: File): Promise<string | undefined> {
    const extension = sanitiseFileName(file.name).split('.').pop() ?? '';
    const parameters: S3FileUpload | undefined = (await axios.put(`/scoring-guide/${ stepName }_${ language }.${ extension }`))?.data;
    return s3uploadFileAndWait(parameters, file);
  },

  deleteScoringGuide: async function (fileName: string): Promise<boolean> {
    return deleteOne(`/scoring-guide/${ fileName }`);
  },

  // --------------------------------------------------------------------------------
  getCronJobs: async function (query: PaginatedQuery): Promise<CronJobsOnPage | undefined> {
    return getPage(`/server/cron`, query);
  },

  getCronJob: async function (id: number): Promise<CronJob | undefined> {
    return getOne(`/server/cron/${ id }`);
  },

  // cannot create: cron jobs are created by the server

  updateCronJob: async function (id: number, job: UpdateCronJob): Promise<CronJob | undefined> {
    return updateOne(job, `/server/cron/${ id }`);
  },

  deleteCronJob: async function (id: number): Promise<boolean> {
    return deleteOne(`/server/cron/${ id }`);
  },

  // --------------------------------------------------------------------------------
  getSpecialists: async function (query: SpecialistQuery): Promise<SpecialistsOnPage | undefined> {
    return getPage(`/specialists`, query);
  },

  getSpecialist: async function (id: number): Promise<Specialist | undefined> {
    return getOne(`/specialist/${ id }`);
  },

  createSpecialist: async function (data: CreateSpecialist): Promise<Specialist | undefined> {
    return createOne(data, `/specialists`);
  },

  updateSpecialist: async function (id: number, data: UpdateSpecialist): Promise<Specialist | undefined> {
    return updateOne(data, `/specialists/${ id }`);
  },

  deleteSpecialist: async function (id: number): Promise<boolean> {
    return deleteOne(`/specialists/${ id }`);
  },

  // --------------------------------------------------------------------------------
  getStatistics: async function (query: StatisticsQuery): Promise<StatisticsOnPage | undefined> {
    return getPage(`/statistics`, query);
  },

  updateStatistics: async function (): Promise<boolean> {
    return isSuccessful(await axios.post('/statistics'));
  },
};
