<template>
  <v-select v-model="locale" class="required" required data-cy="system-language" density="compact" variant="outlined"
            :rules="[rules.required]" :items="languageList" @update:model-value="onChange" />
</template>

<script setup lang="ts">
import { useSession } from '@/compositions/UseSession';
import { useTranslation } from '@/compositions/UseTranslation';
import { computed } from 'vue';
import { toPairs } from 'lodash-es';
import useRules from '@/compositions/UseRules';
import { api } from '@/service/api';

const {rules} = useRules();
const session = useSession();
const {getLanguages, locale} = useTranslation();
const languageList = computed(() => toPairs(getLanguages()).map(([name, title]) => ({value: name, title})));

async function onChange(newValue: string) {
  if (!session.isLoggedIn || !session.user) return;

  session.user.language = newValue;
  await api.updateUser(session.userId, {language: newValue});
  // locale.value = validateLanguage(newValue);
}
</script>
