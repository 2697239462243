/**
 * Language related utilities
 * See also useTranslation()
 *
 * language codes should use BCP 47 like en, en-NZ, en-US, fr, fr-FR, fr-CA
 * lowercase language name, upper case country code for region
 */

export const AllowedLanguages = ['en', 'en-LT', 'en-NE', 'en-FT'] as const;
export type AllowedLanguage = typeof AllowedLanguages[number];
// dummy languages for testing. Should be hidden in production
export const devLanguages: AllowedLanguage[] = ['en-NE', 'en-LT', 'en-FT'];
export const defaultLanguage: AllowedLanguage = 'en';

export function validateLanguage(language: string | AllowedLanguage): AllowedLanguage {
  if (AllowedLanguages.includes(language as AllowedLanguage)) return language as AllowedLanguage;
  return fallbackLanguage(language);
}

export function fallbackLanguage(language: string | AllowedLanguage): AllowedLanguage {
  const parts = language.split('-');
  if (parts.length <= 1) return defaultLanguage;
  const wanted = parts.slice(0, -1).join('-');
  if (AllowedLanguages.includes(wanted as AllowedLanguage)) return wanted as AllowedLanguage;
  return fallbackLanguage(wanted);
}
