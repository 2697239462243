<template>
  <div style="height: 6px; position: sticky; top: 0; z-index: 10">
    <v-progress-linear v-if="loading" color="blue-lighten-3" bg-color="blue-grey" :indeterminate="true"
                       data-cy="ajax-loading-bar" />
  </div>
</template>

<script setup lang="ts">
// show a loading bar if there is an ajax call in progress
import useLoadingState from '@/compositions/UseLoadingState';

const {loading} = useLoadingState();
</script>
