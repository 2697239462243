/**
 * Instantiate all global Vue plugins and attach them to the Vue app
 */
// import { loadFonts } from './webfontloader';
import vuetify from './vuetify';
import i18n from './i18n';
import router from './router';
import type { App } from 'vue';
import enableSentry from './sentry';

export function registerPlugins(app: App) {
  // loadFonts();
  app
    .use(i18n)
    .use(vuetify)
    .use(router)

  enableSentry(app, router);
}
