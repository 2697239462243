import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { VBtn } from 'vuetify/components/VBtn';
import i18n from './i18n';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';

/*
 These colours are used in the mobile app for materials (via Ilya 12 Jul 2023

 static const Color blue1 = Color.fromRGBO(0, 145, 225, 1);
 static const Color blue2 = Color.fromRGBO(0, 65, 155, 1);
 static const Color blue3 = Color.fromRGBO(25, 25, 75, 1);

 static const Color teal1 = Color.fromRGBO(60, 195, 180, 1);
 static const Color teal2 = Color.fromRGBO(0, 145, 123, 1);
 static const Color teal3 = Color.fromRGBO(0, 70, 80, 1);

 static const Color green1 = Color.fromRGBO(160, 215, 95, 1);
 static const Color green2 = Color.fromRGBO(88, 190, 70, 1);
 static const Color green3 = Color.fromRGBO(0, 90, 75, 1);

 static const Color purple1 = Color.fromRGBO(200, 140, 220, 1);
 static const Color purple2 = Color.fromRGBO(150, 70, 190, 1);
 static const Color purple3 = Color.fromRGBO(80, 50, 120, 1);

 static const Color pink1 = Color.fromRGBO(240, 120, 200, 1);
 static const Color pink2 = Color.fromRGBO(225, 20, 150, 1);
 static const Color pink3 = Color.fromRGBO(170, 0, 95, 1);

 static const Color red1 = Color.fromRGBO(250, 125, 85, 1);
 static const Color red2 = Color.fromRGBO(255, 48, 0, 1);
 static const Color red3 = Color.fromRGBO(195, 0, 25, 1);

 for fonts

 static const Color black = Color.fromRGBO(0, 0, 0, 1);
 static const Color white = Color.fromRGBO(255, 255, 255, 1);
 static const Color grey1 = Color.fromRGBO(200, 200, 200, 1);
 static const Color grey2 = Color.fromRGBO(150, 150, 150, 1);
 static const Color grey3 = Color.fromRGBO(75, 80, 85, 1);
 static const Color silver = Color.fromRGBO(166, 169, 170, 1);
 static const Color gold = Color.fromRGBO(172, 151, 81, 1);
 */

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
const vuetify = createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#00419B',
          secondary: '#0091E1',
        },
      },
    },
  },
  aliases: {
    VBtnPrimary: VBtn,
    VBtnSecondary: VBtn,
  },
  defaults: {
    global: {
      ripple: false
    },
    VBreadcrumbs: {
      class: ['pl-0', 'pt-0']
    },
    VInput: {
      hideDetails: 'auto',
    },
    VTextField: {
      hideDetails: 'auto',
    },
    VAutocomplete: {
      hideDetails: 'auto',
    },
    VTextarea: {
      hideDetails: 'auto',
    },
    VSelect: {
      hideDetails: 'auto',
    },
    VRadioGroup: {
      hideDetails: 'auto',
    },
    VCombobox: {
      hideDetails: 'auto',
    },
    VCheckbox: {
      hideDetails: 'auto',
    },
    VBtn: {
      variant: 'elevated',
      class: ['text-none']
    },
    VBtnToggle: {
      variant: 'outlined',
    },
    VCard: {
      variant: 'outlined',
      class: ['mb-3']
    },
    VCardActions: {
      class: ['px-3']
    },
    VDataTable: {
      mobile: false, // disable mobile table because it's buggy with the three dot menu
    },
    VDataTableServer: {
      mobile: false, // disable mobile table because it's buggy with the three dot menu
    },
    VFileInput: {
      // variant: 'outlined',
      density: 'compact',
      prependIcon: ''
    },
    VSwitch: {
      density: 'compact'
    },
    VLabel: {
      style: 'opacity: 1' // override vuetify opacity
    },
    VExpansionPanel: {
      variant: 'accordion',
      expandIcon: 'mdi-chevron-right',
      collapseIcon: 'mdi-chevron-down',
    },
    VExpansionPanelTitle: {
      expandIcon: 'mdi-chevron-right',
      collapseIcon: 'mdi-chevron-down',
    },
    VBtnPrimary: {
      class: ['text-none'],
      color: 'primary',
    },
    VBtnSecondary: {
      class: ['text-none'],
    }
  },
  // see https://vuetifyjs.com/en/features/internationalization/#vue-i18n
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n })
  }
});

export default vuetify;
