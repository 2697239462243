// automatically log out if no activity for a specified time

import { ref, watch } from 'vue';
import { assert, useMousePressed } from '@vueuse/core';
import { useSession } from '@/compositions/UseSession';
import router from '@/plugins/router';

const {pressed} = useMousePressed();

type OnLogoutFunction = () => Promise<void>;
let idleMinutes = 0; // 0 means disabled
let lastActive = Date.now();
let timer: NodeJS.Timeout | undefined = undefined;
let onLogoutCallback: OnLogoutFunction | undefined = undefined;
const autoLoggedOut = ref(false); // for testing

async function logout() {
  if (onLogoutCallback) {
    await onLogoutCallback();
  } else {
    useSession().logout();
    await router.push('/auth/auto-logged-out');
  }
}

function activityDetected() {
  lastActive = Date.now();
}

async function logoutIfIdle() {
  if (idleMinutes <= 0) {
    // ignore if idleMinutes is not set
    return;
  }
  // every minute, check to see if we should log out
  if (Date.now() - lastActive > idleMinutes * 60 * 1000) {
    autoLoggedOut.value = true;
    stopIdleTimer();
    await logout();
  }
}

function startIdleTimer(maxIdleMinutes?: number, onLogout?: () => Promise<void>){
  lastActive = Date.now();
  onLogoutCallback = onLogout;
  if (maxIdleMinutes !== undefined) {
    setMaxIdleMinutes(maxIdleMinutes);
  }
  if (!timer) {
    timer = setInterval(logoutIfIdle, 60 * 1000);
    autoLoggedOut.value = false;
  }
}

function stopIdleTimer() {
  idleMinutes = 0;
  if (timer) {
    clearInterval(timer);
    timer = undefined;
  }
}

function setMaxIdleMinutes(minutes: number) {
  assert(minutes >= 0, 'maxIdleMinutes must be >= 0');
  idleMinutes = minutes;
}

function getMaxIdleMinutes() {
  return idleMinutes;
}

watch(pressed, () => {
  if (pressed) activityDetected();
});

export default function useAutoLogout() {
  return {
    startIdleTimer, activityDetected, setMaxIdleMinutes,
    // the functions below are public for testing only
    stopIdleTimer, getMaxIdleMinutes, autoLoggedOut
  };
}
